/*global $*/
const initMenu = require('./static/js/megamenu')
global.$ = require('jquery');

exports.onInitialClientRender=()=>{

  // require('./static/js/jquery.slicknav')
}


exports.onRouteUpdate=()=>{
  global.$ && initMenu.default();
}