// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-site-page-js": () => import("/opt/build/repo/src/templates/site-page.js" /* webpackChunkName: "component---src-templates-site-page-js" */),
  "component---src-templates-tags-js": () => import("/opt/build/repo/src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calendar-js": () => import("/opt/build/repo/src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-list-js": () => import("/opt/build/repo/src/pages/news/list.js" /* webpackChunkName: "component---src-pages-news-list-js" */),
  "component---src-pages-profiles-js": () => import("/opt/build/repo/src/pages/profiles.js" /* webpackChunkName: "component---src-pages-profiles-js" */),
  "component---src-pages-staff-profiles-js": () => import("/opt/build/repo/src/pages/staff-profiles.js" /* webpackChunkName: "component---src-pages-staff-profiles-js" */),
  "component---src-pages-tags-index-js": () => import("/opt/build/repo/src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

